import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/header/page-header'
import { useStaticQuery, graphql, Link } from 'gatsby'

import new_authors from '../../static/_authors/authors.json'

import Image from 'gatsby-image'
import truncate from '../utils/truncateString'

const Blog = () => {
  const data = useStaticQuery(graphql`
    query Blog {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog_1: file(relativePath: { eq: "blog/blog-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog_2: file(relativePath: { eq: "blog/blog-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog_3: file(relativePath: { eq: "blog/blog-3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog_4: file(relativePath: { eq: "blog/blog-4.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      allAuthorsJson {
        nodes {
          authors {
            firstName
            lastName
            avatar
            email
          }
        }
      }
      allMarkdownRemark(
        limit: 1000
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMM DD, YYYY")
              featuredimage {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
              author
              category
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges
  const authors = data.allAuthorsJson.nodes[0].authors

  // console.log(authors)

  const headerBg = data.bgImage.childImageSharp.fluid

  return (
    <Layout bgColor="#f4f4f9">
      <SEO
        title="IT Blog | Catch the latest trends with us"
        description="Catch the latest trends and best practices in the industry, take a look at the newest technological innovations. Check out our blog for stories you should not miss!"
      />
      <PageHeader
        background={headerBg}
        title="IT BLOG"
        subtitle="Catch the latest trends and best practices in the industry, take a look at the newest technological innovations. Check out our blog for stories you should not miss!"
        thisPage="IT BLOG"
        className="pink"
      />
      <div className="blog-grid container-fluid pb-5">
        <div className="col-md-10 mx-auto pb-5">
          <div className="row">
            {posts &&
              posts.map((post, index) => {
                const author = new_authors.authors.find(
                  item => item.firstName === post.node.frontmatter.author
                )
                {
                  /* console.log(author) */
                }
                return (
                  <BlogItem
                    key={index}
                    category={post.node.frontmatter.category}
                    title={post.node.frontmatter.title}
                    description={post.node.frontmatter.description}
                    to={post.node.fields.slug}
                    image={
                      post.node.frontmatter.featuredimage &&
                      post.node.frontmatter.featuredimage.childImageSharp.fluid
                    }
                    author={post.node.frontmatter.author}
                    date={post.node.frontmatter.date}
                    avatar={author.avatar}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const BlogItem = ({
  category,
  title,
  description,
  image,
  to,
  author,
  date,
  avatar
}) => {
  return (
    <div className="col-md-6 col-lg-3">
      <div className="post">
        <div className="post-inner">
          <div className="post-image">
            <Link className="featured-image" to={to}>
              {image ? <Image fluid={image} /> : ''}
            </Link>
          </div>
          <div className="post-category">
            <a href="/#">{category}</a>
          </div>
          <div className="post-header">
            <h2 className="post-title">
              <Link to={to}>{title}</Link>
            </h2>
          </div>
          <div className="post-content">
            <p>{truncate(description, 200, true)}</p>
          </div>
          <div className="post-meta">
            <div className="post-avatar">
              {avatar
                ? (
                <img src={avatar} />
                  )
                : (
                <img
                  src="https://secure.gravatar.com/avatar/b5ec168bdc21df307b4ef4d5eb39f290?s=80&d=mm&r=g"
                  width="80"
                  height="80"
                  className=""
                  alt=""
                />
                  )}
            </div>
            <div className="post-author-content">
              <span style={{ marginRight: 3 }}>by</span>
              <span className="post-name">
                <Link to={'/#'}>{author}</Link>
              </span>
              <div>
                <span className="post-date">{date}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
